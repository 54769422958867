@import "shared/styles/core";

$font-size-player-text: 12px;

.player {
  display: flex;
  align-items: center;
  grid-column-start: 1;
  grid-column-end: 3;
  width: 100%;
  position: relative;
}

.podcastPlayer {
  display: flex;
  width: 100%;
  gap: ($spacing * 3);
  justify-content: space-between;
}

.playerControls {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  max-width: ($spacing * 25);
  justify-content: flex-end;
  gap: ($spacing * 0.5);
}

.playerInfo {
  position: relative;
  display: flex;
  justify-content: space-between;
  margin: 0;
  font-size: $font-size-player-text;
  color: var(--content-tertiary);
}

.duration,
.listened {
  font-size: var(--font-size-small);
  line-height: ($spacing * 4);
}

.listened {
  display: flex;
  align-items: flex-start;

  .icon {
    color: var(--color-positive);
    margin-left: $spacing;
  }
}

.mixerControls {
  width: 100%;
  height: ($spacing * 0.5);
  position: absolute;
  bottom: -($spacing * 2);
  left: 0;

  &.pushTop {
    bottom: 0;
  }
}

.action {
  display: flex;
  gap: ($spacing * 3);
  align-items: center;
}

.mixerTrack {
  height: ($spacing * 0.5);
  display: flex;
  width: 100%;
  background: transparent;
  opacity: 0;
  transition: opacity 0.3s ease-in;

  &.playing {
    opacity: 1;
  }
}

.mixerProgress {
  height: ($spacing * 0.5);
  width: 100%;
  align-self: center;
}

.mixerThumb {
  height: ($spacing * 2);
  width: ($spacing * 2);
  border: 0;
  box-shadow: none;
  border-radius: 100%;
  background: var(--content-primary);

  &:focus {
    box-shadow: none;
    outline: 0;
  }

  &.white {
    background: var(--core-white);
  }
}

button.playButton {
  height: ($spacing * 10);
  gap: $spacing;
  padding: 0 ($spacing * 7);
  min-width: ($spacing * 39);
  letter-spacing: 0;

  svg {
    pointer-events: none;
  }

  &.sport {
    text-transform: uppercase;
    font-family: var(--font-family-secondary);
  }
}

.audio {
  position: absolute;
  visibility: hidden;
  pointer-events: none;
}

/* Main Feed Dark Theme */
.dark {
  &.player {
    border-color: var(--surface-secondary);
  }

  .podcastPlayer {
    .duration {
      & > svg {
        color: var(--core-white);
      }
    }
  }
}

.player.small {
  align-self: flex-end;
}

@include media-breakpoint-up(md) {
  .player {
    display: flex;
    align-items: center;
    align-self: flex-end;
    width: 100%;
    margin: $spacing 0 0;
    padding: 0;

    &.medium {
      width: 100%;
      margin: auto auto 0;
    }
  }

  .playerControls {
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    max-width: 100%;
    gap: $spacing;
  }

  button.playButton {
    width: ($spacing * 61);
  }

  .player.small {
    width: 100%;
    margin: 0;
    padding: 0;
  }

  .playerControls {
    align-items: flex-start;
    flex-direction: column;
    flex: 1 1 auto;
    max-width: ($spacing * 25);
    justify-content: flex-end;
    gap: ($spacing * 0.5);
  }

  .player.small {
    button.playButton {
      width: auto;
    }
  }
}
