@import "shared/styles/core";

$contextual-menu-width: 260px;
$box-shadow-color: #00000014;

.container {
  position: absolute;
  width: $contextual-menu-width;
  background: var(--surface-tertiary);
  color: var(--content-primary);
  padding: ($spacing * 3) ($spacing * 2);
  border: 1px solid var(--gray-g100);
  border-radius: ($spacing * 2.5);
  box-shadow: 0 ($spacing * 0.5) ($spacing * 4) $box-shadow-color;
  top: ($spacing * 8);
  right: -($spacing * 2);
  z-index: $layer-1;

  &.latest {
    right: ($spacing * 2);
  }
}

.button {
  width: 100%;
  color: var(--content-primary);
  height: ($spacing * 11);
  border-radius: ($spacing * 1.5);
  background: none;
  border: none;
  font-size: 14px;
  line-height: ($spacing * 5);
  display: flex;
  align-items: center;
  gap: ($spacing * 3);
  padding: ($spacing * 3) ($spacing * 2);
  cursor: pointer;

  &:hover {
    background: var(--gray-g50);
  }

  &:active {
    background: var(--gray-g50);
  }
}

.iconButton {
  position: absolute;
  top: 0;
  right: 0;
  color: var(--content-primary);
  padding: 0;

  &.feed {
    position: relative;
    border: 0;
  }
}

.player {
  &.container {
    top: ($spacing * 13);
    right: ($spacing * 3);
  }

  &.iconButton {
    top: ($spacing * 4);
    right: ($spacing * 3);
  }
}

.link {
  text-decoration: none;
  color: var(--content-primary);

  &:hover,
  &:active {
    color: var(--content-primary);
  }
}

@include media-breakpoint-up(xl) {
  .container {
    top: ($spacing * 8);
    right: -($spacing * 47);
  }
}
