@font-face {
  font-family: HelveticaNowText-Regular;
  font-style: normal;
  font-weight: normal;
  src: url("/fonts/HelveticaNowText.woff") format("woff");
}

@font-face {
  font-family: HelveticaNowText-Bold;
  font-style: normal;
  font-weight: bold;
  src: url("/fonts/HelveticaNowTextBold.woff") format("woff");
}

@font-face {
  font-family: HelveticaNowDisplayBlk;
  src: url("/fonts/HelveticaNowDisplayBlk.woff") format("woff");
}
