@import "shared/styles/core";

$button-height: 72px;

.shareModal {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ($spacing * 4);
  border-bottom: $default-border-width solid var(--core-black);
}

.close {
  display: flex;
  align-items: center;
  justify-content: center;
  appearance: none;
  border: 0;
  background: transparent;
  padding: 0;
  width: ($spacing * 4);
  height: ($spacing * 4);
  cursor: pointer;

  .closeIcon {
    color: var(--content-primary);
    display: block;
    width: 64%;
    height: 64%;
  }

  &:hover,
  &:active,
  &:focus {
    box-shadow: none;
    outline: 0;
  }
}

.actions {
  display: flex;
  flex-wrap: wrap;
}

.shareButton {
  display: flex;
  align-items: center;
  appearance: none;
  color: var(--content-primary);
  padding: 0 ($spacing * 5);
  border: $default-border-width solid var(--core-black);
  border-top: 0;
  border-right: 0;
  background: transparent;
  flex: 0 0 50%;
  height: $button-height;
  max-width: 50%;
  font-family: inherit;
  font-size: var(--font-size-medium);
  letter-spacing: -0.015em;
  cursor: pointer;
  text-decoration: none;
  transition: color 0.4s ease-in;

  &:nth-child(odd) {
    border-left: 0;
  }

  .socialIcon {
    display: block;
    margin: 0 ($spacing * 4) 0 0;
    width: ($spacing * 6);
    height: ($spacing * 6);
    transition: fill 0.4s ease-in;
  }

  &:hover,
  &:active,
  &:focus {
    box-shadow: none;
    outline: 0;
    color: var(--link-on-surface);
  }

  &.insta {
    width: 100%;
    max-width: 100%;
    flex: 1;
  }

  .spinner {
    margin-left: ($spacing * 4);
  }

  &.messenger {
    display: none;
  }

  &.copyLink {
    border-left: 0;
  }

  &.full {
    flex: 1 1 100%;
    max-width: none;
    justify-content: center;
    border-left: 0;
  }
}

.copySource {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: $default-border-width;
  width: $default-border-width;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
}

@include media-breakpoint-up(sm) {
  .shareModal {
    left: 50%;
    width: 100%;
    max-width: 440px;
    transform: translate(-50%, -50%);
  }
}

@include media-breakpoint-up(md) {
  .shareButton {
    &.messenger {
      display: none;
    }

    &.full {
      flex: 1 1 100%;
      max-width: none;
      justify-content: center;
    }
  }
}
