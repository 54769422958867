@import "shared/styles/core";

$font-size-podcast-title-mobile: 12px;
$font-size-podcast-title-tablet: 14px;

$podcast-tile-small-image-space: $podcast-tile-mobile-image +
  $podcast-tile-grid-gap;
$podcast-tile-big-image-space: $podcast-tile-desktop-image +
  $podcast-tile-grid-gap;

$podcast-tile-small-width: 320px;
$podcast-tile-small-desktop-width: 343px;

.podcastTile {
  padding: ($spacing * 2) ($spacing * 2) ($spacing * 3);
  position: relative;
  background: var(--surface-tertiary);
  display: grid;
  grid-gap: $podcast-tile-grid-gap;
  grid-template-columns: $podcast-tile-mobile-image calc(
      100% - #{$podcast-tile-small-image-space}
    );
  grid-template-rows: repeat(3, auto);
  border: 1px solid var(--gray-g100);
  border-radius: $default-border-radius;

  &:not(:last-child) {
    margin: 0 0 ($spacing * 5);
  }
}

.image {
  grid-column-start: 1;
  grid-column-end: 2;
  border-radius: $default-border-radius;
  cursor: pointer;
  width: $podcast-tile-mobile-image;
  height: $podcast-tile-mobile-image;
  position: relative;

  .img {
    border-radius: $default-border-radius;
  }
}

.meta {
  grid-column-start: 2;
  grid-column-end: 3;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: $spacing;
}

.action {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.uploadTime {
  color: var(--color-dark-gray);
  font-size: var(--font-size-small);
  line-height: ($spacing * 4);
}

.podcast {
  display: inline-block;
  max-width: 100%;
  font-size: $font-size-podcast-title-mobile;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: var(--content-primary);
  text-decoration: none;
  overflow: hidden;
  margin-right: ($spacing * 6);
}

.podcastLink,
.episodeLink {
  text-decoration: none;
  color: var(--content-primary);
}

.episodeLink {
  grid-column-start: 1;
  grid-column-end: 3;
}

.title {
  font-size: ($spacing * 4.5);
}

.description {
  line-height: ($spacing * 4);
  display: block;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;

  &:empty {
    display: none;
  }
}

/* Dark Tile for Main Feed */
.dark {
  &.podcastTile {
    background: var(--core-black);
    color: var(--core-white);
    border: 0;
  }

  .image {
    border-color: var(--surface-secondary);
  }

  .podcastLink,
  .episodeLink {
    color: var(--core-white);
  }
}

/* Minimal Variant mainly for other places than feeds */
.minimal {
  .podcastLink {
    display: none;
  }
}

/* medium variant */
.podcastTile.medium {
  display: flex;
  flex-direction: column;

  .image,
  .meta {
    grid-column: auto;
    grid-row: auto;
  }

  .image {
    border: 0;
    object-fit: cover;
    object-position: top;
    height: $tile-image-size-sm;
    width: 100%;
  }

  .meta {
    padding: ($spacing * 3);
    border-top: $default-border-width solid var(--core-black);
  }

  .podcast {
    color: var(--color-podcast-tile-title);
    margin-bottom: $spacing;
  }

  .episode-link {
    height: auto;
  }
}

.podcastTile.small {
  min-width: $podcast-tile-small-width;
  min-height: 100%;
  grid-template-rows: repeat(2, auto);
  box-shadow: 0 2px 12px rgb(0 0 0 / 8%);

  &:not(:last-child) {
    margin-bottom: 0;
  }

  .title {
    font-size: ($spacing * 4);
  }
}

@include media-breakpoint-up(md) {
  .podcastTile {
    column-gap: ($spacing * 3);
    row-gap: ($spacing);
    padding: ($spacing * 3);
  }

  .image {
    grid-row-start: 1;
    grid-row-end: 3;
    border-bottom: 0;
    height: $podcast-tile-desktop-image;
    width: $podcast-tile-desktop-image;
  }

  .description {
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 2;
    line-height: ($spacing * 5);
    font-size: $font-size-podcast-title-tablet;
  }

  .meta {
    margin: 0;
    gap: 0;
  }

  .podcast {
    display: flex;
    align-items: center;
    font-size: $font-size-podcast-title-tablet;
    margin-bottom: ($spacing * 2);
  }

  .title {
    font-size: ($spacing * 6);
  }

  .podcastTile {
    grid-template-columns: $podcast-tile-desktop-image calc(
        100% - #{$podcast-tile-big-image-space}
      );

    &.medium {
      margin-bottom: 0;

      > * {
        padding: 0;
      }

      .meta {
        padding-bottom: ($spacing * 5);
      }
    }
  }

  .podcastTile.small {
    min-width: $podcast-tile-small-desktop-width;
    column-gap: $podcast-tile-grid-gap;
    padding: ($spacing * 2) ($spacing * 2) ($spacing * 3);
    grid-template-columns: $podcast-tile-mobile-image calc(
        100% - #{$podcast-tile-small-image-space}
      );

    .image {
      width: $podcast-tile-mobile-image;
      height: $podcast-tile-mobile-image;
    }

    .title {
      font-size: ($spacing * 4);
    }

    .meta {
      margin: 0;
      gap: $spacing;
    }

    .podcast {
      font-size: $font-size-podcast-title-mobile;
      margin-bottom: $spacing;
      display: inline-block;
    }
  }
}

@include media-breakpoint-up(lg) {
  .podcastTile.medium {
    .image {
      height: $tile-image-size;
      width: 100%;
    }
  }
}
