/* Breakpoints */
$xs: 0;
$sm: 640px;
$md: 768px;
$lg: 1024px;
$xl: 1240px;
$xxl: 1400px;
$xxxl: 1920px;

$grid-breakpoints: (
  xs: $xs,
  sm: $sm,
  md: $md,
  lg: $lg,
  xl: $xl,
  xxl: $xxl,
) !default;

/* Layout */
$spacing: 4px;

$container-max-width: 1280px;
$default-grid-gap: ($spacing * 8);
$bottom-bars-height: 52px;
$artwork-size: 48px;

$bottom-nav-height: ($spacing * 11);
$bottom-player-height: $bottom-bars-height;
$bottom-player-timeline-height: $bottom-bars-height;
$bottom-player-trigger-height: 36px;

$bottom-navigation-max-height: $bottom-nav-height + $bottom-player-height;
$top-navigation-mobile-spacing: ($spacing * 22);
$both-navigation-height: $bottom-navigation-max-height +
  $top-navigation-mobile-spacing;

$logobar-height: 42px !default;
$topbar-min-height: 60px;
$navbar-height: 48px;
$mobile-article-max-height: 278px;
$desktop-article-max-height: 518px;
$featured-content-width: 284px;

$radio-track-tile-mobile-size: 56px;
$radio-track-tile-desktop-size: 64px;

$schedule-track-bar-height: 48px;

/* Podcast */
$podcast-tile-grid-gap: ($spacing * 2);
$podcast-tile-mobile-image: 90px;
$podcast-tile-desktop-image: 110px;

$podcast-card-mobile-image: 114px;
$podcast-card-desktop-image: 148px;
$podcast-card-small-image: 114px;

/* Layout Common Variables */
$default-border-width: 2px;
$default-border-radius: 4px;
$default-box-shadow: 0 ($spacing * 0.5) ($spacing * 3) #00000014;

$small-icon-button-size: 32px;
$medium-icon-button-size: 40px;
$big-icon-button-size: 48px;

$fixed-menu-spacing-desktop: 108px;
$fixed-menu-spacing-mobile: 150px;
$fixed-menu-spacing-pwa: 96px;

/* Modals */
$backdrop-blur-value: 12px;
$modal-sm-width: 480px;
$modal-md-width: 880px;

/* Layers */
$auth-layer: 4000;
$top-layer: 3000;
$layer-9: 90;
$layer-8: 80;
$layer-7: 70;
$layer-6: 60;
$layer-5: 50;
$layer-4: 40;
$layer-3: 30;
$layer-2: 20;
$layer-1: 10;
$bottom-layer: 0;
$negative-layer: -1;

/* Recommended tiles */
$tile-image-size-sm: 188px;
$tile-image-size: 250px;
$tile-width: 284px;

/* Login/register form */
$auth-form-width: 343px;
