$transition-cubic: cubic-bezier(0.17, 0.67, 0.39, 1.01);

@keyframes waveAnimation {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-192px);
  }
}

@keyframes marquee {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(-100%);
  }
}
