@import "shared/styles/core";

$size-mobile-bookmark-icon: 40px;
$size-desktop-bookmark-icon: 32px;

.indicatorBookmark {
  color: var(--foundation-on-primary);

  & > svg {
    path {
      transition: fill 0.2s ease-in, stroke 0.2s ease-in;
    }
  }

  &.active {
    & > svg {
      path {
        fill: var(--foundation-on-primary);
        stroke: var(--foundation-on-primary);
      }
    }
  }
}

.primary {
  & > svg {
    * {
      fill: var(--surface-tertiary);
    }

    &:hover {
      & > svg {
        filter: none;
        transition: fill 0.2s ease-in, stroke 0.2s ease-in;

        * {
          stroke: var(--link-on-surface);
        }
      }
    }

    &.active {
      background: var(--primary-p500);
      color: var(--foundation-on-primary);

      & > svg {
        * {
          fill: var(--foundation-on-primary);
        }
      }

      &:hover {
        & > svg {
          transition: fill 0.2s ease-in, stroke 0.2s ease-in;

          * {
            fill: transparent;
            stroke: var(--foundation-on-primary);
          }
        }
      }
    }
  }
}

.dark {
  & > svg {
    * {
      fill: transparent;
    }
  }

  &:hover {
    color: var(--core-white);
    background: var(--core-black);

    & > svg {
      * {
        fill: var(--foundation-on-primary);
      }
    }
  }

  &.active {
    color: var(--color-button-text-primary);
    background: var(--primary-p500);

    & > svg {
      * {
        fill: var(--foundation-on-primary);
      }
    }

    &:hover {
      & > svg {
        transition: fill 0.2s ease-in, stroke 0.2s ease-in;

        * {
          fill: transparent;
          stroke: var(--foundation-on-primary);
        }
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .indicatorBookmark {
    color: var(--content-primary);

    &.active {
      & > svg {
        path {
          fill: var(--content-primary);
          stroke: var(--content-primary);
        }
      }
    }
  }
}
