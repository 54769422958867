/* stylelint-disable */
@import "shared/styles/core";

.ad {
  text-align: center;
  max-width: 100%;
  overflow: hidden;
}

.wrapper {
  @extend .ad;

  iframe {
    margin: ($spacing * 4) 0;
  }

  div[id^="google_ads_iframe"]:not(:empty) {
    &::before,
    &::after {
      display: block;
      font-size: var(--font-size-article-feed-tag);
      font-family: var(--font-family-sections-heading);
      font-style: var(--font-style-sections-heading);
      font-weight: var(--font-weight-sections-heading);
      line-height: 1.1;
      color: var(--content-primary);
      text-transform: uppercase;
      background-image: linear-gradient(
        180deg,
        var(--content-primary) 50%,
        transparent 25%,
        transparent 25%
      );
      background-size: 100% 4px;
      background-repeat: no-repeat;
    }

    &::before {
      content: "reklama";
      text-align: left;
      background-position: top 6px left 80px;
    }

    &::after {
      content: "po reklamie";
      text-align: right;
      background-position: top 6px right 110px;
      margin-bottom: ($spacing * 4);
    }
  }
}

.articleTop,
.videoTop {
  @extend .ad;

  display: none;

  div[id^="google_ads_iframe"]:not(:empty) {
    iframe {
      margin: ($spacing * 8) 0;
    }

    &::before,
    &::after {
      display: block;
      font-size: var(--font-size-article-feed-tag);
      font-family: var(--font-family-sections-heading);
      font-style: var(--font-style-sections-heading);
      font-weight: var(--font-weight-sections-heading);
      line-height: 1.1;
      color: var(--content-primary);
      text-transform: uppercase;
      background-image: linear-gradient(
        180deg,
        var(--content-primary) 50%,
        transparent 25%,
        transparent 25%
      );
      background-size: 100% 4px;
      background-repeat: no-repeat;
      max-width: $container-max-width;
      margin: 0 auto;
    }

    &::before {
      content: "reklama";
      text-align: left;
      background-position: top 6px left 80px;
    }

    &::after {
      content: "po reklamie";
      text-align: right;
      background-position: top 6px right 110px;
      margin-bottom: ($spacing * 4);
    }
  }
}

.articleMiddle {
  @extend .ad;

  div[id^="google_ads_iframe"]:not(:empty) {
    iframe {
      margin: ($spacing * 3) 0;
    }

    &::before,
    &::after {
      display: block;
      font-size: var(--font-size-article-feed-tag);
      font-family: var(--font-family-sections-heading);
      font-style: var(--font-style-sections-heading);
      font-weight: var(--font-weight-sections-heading);
      line-height: 1.1;
      color: var(--content-primary);
      text-transform: uppercase;
      background-image: linear-gradient(
        180deg,
        var(--content-primary) 50%,
        transparent 25%,
        transparent 25%
      );
      background-size: 100% 4px;
      background-repeat: no-repeat;
    }

    &::before {
      content: "reklama";
      text-align: left;
      background-position: top 6px left 80px;
      margin-top: ($spacing * 3);
    }

    &::after {
      content: "po reklamie";
      text-align: right;
      background-position: top 6px right 110px;
      margin-bottom: ($spacing * 5);
    }
  }
}

.articleMobileTop {
  @extend .ad;

  div[id^="google_ads_iframe"]:not(:empty) {
    iframe {
      margin: ($spacing * 4) 0;
    }

    &::before,
    &::after {
      display: block;
      font-size: var(--font-size-article-feed-tag);
      font-family: var(--font-family-sections-heading);
      font-style: var(--font-style-sections-heading);
      font-weight: var(--font-weight-sections-heading);
      line-height: 1.1;
      color: var(--content-primary);
      text-transform: uppercase;
      background-image: linear-gradient(
        180deg,
        var(--content-primary) 50%,
        transparent 25%,
        transparent 25%
      );
      background-size: 100% 4px;
      background-repeat: no-repeat;
    }

    &::before {
      content: "reklama";
      text-align: left;
      background-position: top 6px left 80px;
      margin-top: ($spacing * 4);
    }

    &::after {
      content: "po reklamie";
      text-align: right;
      background-position: top 6px right 110px;
      margin-bottom: 0;
    }
  }
}

.articleSlide {
  @extend .ad;

  div[id^="google_ads_iframe"]:not(:empty) {
    iframe {
      margin: ($spacing * 4) 0;
    }

    &::before,
    &::after {
      display: block;
      font-size: var(--font-size-article-feed-tag);
      font-family: var(--font-family-sections-heading);
      font-style: var(--font-style-sections-heading);
      font-weight: var(--font-weight-sections-heading);
      line-height: 1.1;
      color: var(--content-primary);
      text-transform: uppercase;
      background-image: linear-gradient(
        180deg,
        var(--content-primary) 50%,
        transparent 25%,
        transparent 25%
      );
      background-size: 100% 4px;
      background-repeat: no-repeat;
    }

    &::before {
      content: "reklama";
      text-align: left;
      background-position: top 6px left 80px;
    }

    &::after {
      content: "po reklamie";
      text-align: right;
      background-position: top 6px right 110px;
      margin-bottom: ($spacing * 4);
    }
  }
}

.articleBottom,
.videoBottom,
.podcastBottom {
  @extend .ad;

  div[id^="google_ads_iframe"]:not(:empty) {
    iframe {
      margin: ($spacing * 4) 0;
    }

    &::before,
    &::after {
      display: block;
      font-size: var(--font-size-article-feed-tag);
      font-family: var(--font-family-sections-heading);
      font-style: var(--font-style-sections-heading);
      font-weight: var(--font-weight-sections-heading);
      line-height: 1.1;
      color: var(--content-primary);
      text-transform: uppercase;
      background-image: linear-gradient(
        180deg,
        var(--content-primary) 50%,
        transparent 25%,
        transparent 25%
      );
      background-size: 100% 4px;
      background-repeat: no-repeat;
    }

    &::before {
      content: "reklama";
      text-align: left;
      background-position: top 6px left 80px;
    }

    &::after {
      content: "po reklamie";
      text-align: right;
      background-position: top 6px right 110px;
      margin-bottom: ($spacing * 4);
    }
  }
}

.videoBottom,
.podcastBottom {
  iframe {
    margin-bottom: ($spacing * 10);
  }
}

.homeMobileTop {
  @extend .ad;

  iframe {
    margin: ($spacing * 4) 0;
  }

  div[id^="google_ads_iframe"]:not(:empty) {
    &::before,
    &::after {
      display: block;
      font-size: var(--font-size-article-feed-tag);
      font-family: var(--font-family-sections-heading);
      font-style: var(--font-style-sections-heading);
      font-weight: var(--font-weight-sections-heading);
      line-height: 1.1;
      color: var(--content-primary);
      text-transform: uppercase;
      background-image: linear-gradient(
        180deg,
        var(--content-primary) 50%,
        transparent 25%,
        transparent 25%
      );
      background-size: 100% 4px;
      background-repeat: no-repeat;
    }

    &::before {
      content: "reklama";
      text-align: left;
      background-position: top 6px left 80px;
    }

    &::after {
      content: "po reklamie";
      text-align: right;
      background-position: top 6px right 110px;
      margin-bottom: ($spacing * 4);
    }
  }
}

.homeTop {
  @extend .ad;

  display: none;

  iframe {
    margin: ($spacing * 8) 0;
  }
}

.podcastBottom {
  @extend .ad;

  iframe {
    margin-bottom: ($spacing * 9);
  }
}

.authorTop {
  @extend .ad;

  iframe {
    margin-bottom: ($spacing * 6);
  }
}

.authorBottom {
  @extend .ad;

  iframe {
    margin-bottom: ($spacing * 15);
  }

  &.page {
    iframe {
      margin-bottom: ($spacing * 8);
    }
  }
}
.sidebar {
  @extend .ad;

  position: sticky;
  top: ($spacing * 12);
  margin-bottom: ($spacing * 12);

  iframe {
    margin-top: ($spacing * 18);
  }

  div[id^="google_ads_iframe"]:not(:empty) {
    iframe {
      margin: ($spacing * 6) 0;
    }

    &::before,
    &::after {
      display: block;
      font-size: var(--font-size-article-feed-tag);
      font-family: var(--font-family-sections-heading);
      font-style: var(--font-style-sections-heading);
      font-weight: var(--font-weight-sections-heading);
      line-height: 1.1;
      color: var(--content-primary);
      text-transform: uppercase;
      background-image: linear-gradient(
        180deg,
        var(--content-primary) 50%,
        transparent 25%,
        transparent 25%
      );
      background-size: 100% 4px;
      background-repeat: no-repeat;
    }

    &::before {
      content: "reklama";
      text-align: left;
      background-position: top 6px left 80px;
      margin-top: ($spacing * 16);
    }

    &::after {
      content: "po reklamie";
      text-align: right;
      background-position: top 6px right 110px;
      margin-bottom: 0;
    }
  }

  &.article {
    top: ($spacing * 12);
    margin-bottom: ($spacing * 7);
  }

  &.podcast {
    margin-bottom: ($spacing * 12);

    iframe {
      margin-top: ($spacing * 12);
    }
  }

  &.episode {
    margin-bottom: ($spacing * 25);
  }

  &.video {
    iframe {
      margin-top: 0;
    }
  }

  &.author {
    display: none;

    &.page {
      margin-bottom: 0;
    }

    iframe {
      margin-top: 0;
    }
  }
}

.podcastTop {
  @extend .ad;

  div[id^="google_ads_iframe"]:not(:empty) {
    iframe {
      margin: ($spacing * 4) 0;
    }

    &::before,
    &::after {
      display: block;
      font-size: var(--font-size-article-feed-tag);
      font-family: var(--font-family-sections-heading);
      font-style: var(--font-style-sections-heading);
      font-weight: var(--font-weight-sections-heading);
      line-height: 1.1;
      color: var(--content-primary);
      text-transform: uppercase;
      background-image: linear-gradient(
        180deg,
        var(--content-primary) 50%,
        transparent 25%,
        transparent 25%
      );
      background-size: 100% 4px;
      background-repeat: no-repeat;
    }

    &::before {
      content: "reklama";
      text-align: left;
      background-position: top 6px left 80px;
      margin-top: ($spacing * 4);
    }

    &::after {
      content: "po reklamie";
      text-align: right;
      background-position: top 6px right 110px;
      margin-bottom: ($spacing * 4);
    }
  }

  &.podcastPage {
    iframe {
      margin-top: ($spacing * 8);
    }
  }

  &.episodePage {
    div[id^="google_ads_iframe"]:not(:empty) {
      &::before {
        margin: ($spacing * 4) ($spacing * 4) 0;
      }
      &::after {
        margin: 0 ($spacing * 4) ($spacing * 4);
      }
    }
  }
}

.musicTop {
  @extend .podcastTop;
  background-color: var(--surface-secondary);

  &.sections {
    div[id^="google_ads_iframe"]:not(:empty) {
      &::before {
        margin-top: ($spacing * 10);
      }

      &::after {
        margin-bottom: ($spacing * 10);
      }
    }
  }

  &.white {
    background-color: transparent;

    div[id^="google_ads_iframe"]:not(:empty) {
      &::before {
        margin-top: 0;
      }

      &::after {
        margin-bottom: ($spacing * 6);
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .wrapper {
    iframe {
      margin-bottom: ($spacing * 8);
    }
  }

  .articleSlide {
    div[id^="google_ads_iframe"]:not(:empty) {
      &::after {
        margin-bottom: ($spacing * 8);
      }
    }
  }

  .articleBottom {
    div[id^="google_ads_iframe"]:not(:empty) {
      &::after {
        margin-bottom: 0;
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .articleMobileTop,
  .homeMobileTop {
    display: none;
  }

  .articleTop,
  .videoTop {
    display: block;
    padding-top: ($spacing * 12);
    background-color: var(--surface-secondary);
  }

  .articleTop {
    background-color: var(--surface-primary);
  }

  .podcastTop {
    div[id^="google_ads_iframe"]:not(:empty) {
      iframe {
        margin: ($spacing * 8) 0;
      }

      &::before {
        margin-top: ($spacing * 8);
      }

      &::after {
        margin-bottom: 0;
      }
    }

    &.episodePage {
      div[id^="google_ads_iframe"]:not(:empty) {
        &::before {
          margin-top: ($spacing * 10);
        }

        &::after {
          margin-bottom: ($spacing * 4);
        }
      }
    }
  }

  .articleMiddle {
    div[id^="google_ads_iframe"]:not(:empty) {
      &::after {
        margin-bottom: ($spacing * 6);
      }
    }
  }

  .articleBottom,
  .videoBottom,
  .podcastBottom {
    iframe {
      margin-top: ($spacing * 10);
    }
  }

  .authorTop {
    iframe {
      margin-bottom: ($spacing * 8);
    }
  }

  .authorBottom {
    iframe {
      margin-bottom: ($spacing * 21);
    }
  }

  .musicTop {
    &.white {
      div[id^="google_ads_iframe"]:not(:empty) {
        &::after {
          margin-bottom: ($spacing * 12);
        }
      }
    }
  }
}

@include media-breakpoint-up(xl) {
  .homeTop {
    display: block;

    div[id^="google_ads_iframe"]:not(:empty) {
      &::before,
      &::after {
        display: block;
        font-size: var(--font-size-article-feed-tag);
        font-family: var(--font-family-sections-heading);
        font-style: var(--font-style-sections-heading);
        font-weight: var(--font-weight-sections-heading);
        line-height: 1.1;
        color: var(--content-primary);
        text-transform: uppercase;
        background-image: linear-gradient(
          180deg,
          var(--content-primary) 50%,
          transparent 25%,
          transparent 25%
        );
        background-size: 100% 4px;
        background-repeat: no-repeat;
      }

      &::before {
        content: "reklama";
        text-align: left;
        background-position: top 6px left 80px;
      }

      &::after {
        content: "po reklamie";
        text-align: right;
        background-position: top 6px right 110px;
        margin-bottom: ($spacing * 8);
      }
    }
  }

  .podcastBottom {
    iframe {
      margin-bottom: ($spacing * 9);
    }
  }

  .podcastTop {
    &.podcastPage {
      iframe {
        margin-bottom: ($spacing * 8);
      }
    }
  }

  .sidebar {
    &.author {
      display: block;
    }
  }
}

/* stylelint-enable */
