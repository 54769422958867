@import "shared/styles/core";

$checkbox-size: 24px;

.checkbox {
  position: relative;
  width: $checkbox-size;
  height: $checkbox-size;
  background-color: var(--surface-tertiary);
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--primary-p500);
  cursor: pointer;

  &.error {
    border-color: var(--color-negative);
    box-shadow: $default-border-width $default-border-width 0
      var(--color-negative);
    color: var(--color-negative);
  }

  &.disabled {
    background-color: var(--gray-g50);
    border-color: var(--content-tertiary);
    box-shadow: none;
    cursor: default;
  }

  &.first {
    @include border-with-shadow;
  }

  &.second {
    border: 2px solid var(--content-primary);
  }
}

.icon {
  color: inherit;
  display: none;
}

.input {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  visibility: hidden;

  &:checked + .icon {
    display: block;
  }
}
