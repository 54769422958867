@import "shared/styles/core";

.icon {
  margin-bottom: ($spacing * 3);
}

.children {
  margin: ($spacing * 4) 0 ($spacing * 6);
  text-align: center;
}

.actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  & > * {
    flex: 0 0 calc(50% - #{$spacing * 2});
    max-width: calc(50% - #{$spacing * 2});
  }
}

.text {
  margin-top: ($spacing * 6);
}

.checkbox {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: ($spacing * 6);

  .label {
    padding-left: ($spacing * 4);
  }
}

@include media-breakpoint-up(sm) {
  .children {
    margin: ($spacing * 6) 0 ($spacing * 10);
  }
}
