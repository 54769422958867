@function breakpoint-min($name, $breakpoints: $grid-breakpoints) {
  $min: map-get($breakpoints, $name);

  @return if($min != 0, $min, null);
}

@mixin media-breakpoint-up($name, $breakpoints: $grid-breakpoints) {
  $min: breakpoint-min($name, $breakpoints);

  @if $min {
    @media only screen and (min-width: $min) {
      @content;
    }
  } @else {
    @content;
  }
}

@mixin media-breakpoint-down($name, $breakpoints: $grid-breakpoints) {
  $max: breakpoint-min($name, $breakpoints);

  @if $max {
    @media only screen and (max-width: $max) {
      @content;
    }
  } @else {
    @content;
  }
}

@mixin media-breakpoint-down($name, $breakpoints: $grid-breakpoints) {
  $max: breakpoint-min($name, $breakpoints);

  @if $max {
    @media only screen and (max-width: $max) {
      @content;
    }
  } @else {
    @content;
  }
}

@mixin heading-base {
  font-family: var(--font-family-heading);
  font-weight: var(--font-weight-sections-heading);
  font-style: var(--font-style-hx);
  line-height: 1.2;
}

@mixin secondary-heading-base {
  font-family: var(--font-family-secondary);
  font-weight: var(--font-weight-secondary);
  font-style: var(--font-style-hx);
  line-height: 1.16;
}

@mixin dotted-line {
  width: 100%;
  height: $default-border-width;
  background-image: linear-gradient(
    90deg,
    var(--content-primary),
    var(--content-primary) 45%,
    transparent 45%,
    transparent 100%
  );
  background-size: 3px $default-border-width;
}

@mixin dotted-line-white {
  width: 100%;
  height: $default-border-width;
  background-image: linear-gradient(
    90deg,
    var(--core-white),
    var(--core-white) 45%,
    transparent 45%,
    transparent 100%
  );
  background-size: 3px $default-border-width;
}

@mixin border-with-shadow {
  border: $default-border-width solid var(--core-black);
  box-shadow: $default-border-width $default-border-width 0 var(--core-black);
}

@mixin border-with-shadow-pressed {
  box-shadow: inset $default-border-width $default-border-width 0
    var(--core-black);
}

@mixin shadow {
  box-shadow: $default-border-width $default-border-width 0 var(--core-black);
}

@mixin button-font {
  font-family: var(--font-family-button);
  font-style: var(--font-style-hx);
  font-weight: var(--font-weight-sections-heading);
}
