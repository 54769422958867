@import "shared/styles/core";

.progress-bar {
  @include dotted-line();
}

.progress {
  display: block;
  background: var(--content-primary);
  height: 100%;
  width: auto;
}

.dark {
  &.progress-bar {
    background-image: linear-gradient(
      90deg,
      var(--core-white),
      var(--core-white) 45%,
      transparent 45%,
      transparent 100%
    );
  }

  .progress {
    background: var(--core-white);
  }
}

.animate {
  .progress {
    transition: width 0.24s ease-in;
  }
}

.roundPrimary {
  background-image: none;
  height: $spacing;
  background: var(--gray-g500);
  border-radius: ($spacing * 25);
  max-width: ($spacing * 25);

  .progress {
    background: var(--color-negative);
    border-radius: ($spacing * 25);
  }
}
