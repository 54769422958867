@import "shared/styles/core";

.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: 0;
  width: calc(100% - #{$spacing * 6});
  transform: translate(-50%, -50%);

  &.primary {
    padding: ($spacing * 4);
    background: var(--gray-g50);
  }

  &.secondary {
    background: var(--surface-tertiary);
  }

  &.small {
    max-width: $modal-sm-width;
  }

  &.large {
    max-width: $modal-md-width;
  }

  &:focus {
    outline: 0;
    box-shadow: none;
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: (-$spacing * 4);
  background: rgb(0 0 0 / 75%);
  z-index: $top-layer;
  overflow-y: scroll;

  @supports (backdrop-filter: blur()) {
    backdrop-filter: blur($backdrop-blur-value);
  }

  &.second {
    background: rgb(0 0 0 / 80%);
    backdrop-filter: blur($spacing * 2);
  }
}

.isModalOpen {
  overflow: hidden;
}

@include media-breakpoint-up(sm) {
  .modal {
    width: 100%;

    &.first {
      @include shadow();

      border: $default-border-width solid var(--core-black);
    }

    &.second {
      background-color: var(--surface-tertiary);
      border: 1px solid var(--gray-g50);
      border-radius: $spacing;
    }
  }
}
